import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

const Budgets = ({ title }) => {
  return (
    <div className="divisions">
      <div className="container">
        <h1 className="h1">{title}</h1>
        {/* <Link className="link" to="">download</Link> */}
      </div>
      <div className="container">
        <Table bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Size</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vision for 2025.pdf</td>
              <td>136.09 KB</td>
              <td><a className="link" href='../../assets/download/vision2025.pdf' download >Download</a></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Budgets;