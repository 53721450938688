import React, { useEffect, useState } from "react";
import aboutHeader from "../../assets/images/about.jpg";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Link, useParams } from "react-router-dom";
import "./notice.css";
import Notices from "./Notices";
import Tenders from "./Tenders";
import Schemes from "./Schemes";
import Events from "./Events";
import { fetchApi } from "../../apis/FatchApis";

const Notice = () => {
  const params = useParams();
  const defaultTab = parseInt(params.idx);
//   const [defaultTab, setDefaultTab] = useState(parseInt(params.idx));
  // const [currentTab, setCurrentTab] = useState(localStorage.getItem('currentTab') || defaultTab);
  const [currentTab, setCurrentTab] = useState(defaultTab);
  const [getNotices, setGetNotices] = useState([]);
  const [getTender, setGetTender] = useState([]);
  const [getEvent, setGetEvent] = useState([]);
  const [getSchemes, setSchemes] = useState([]);

  const tabData = [
    {
      id: 1,
      title: "Notice",
    },
    {
      id: 2,
      title: "Tender",
    },
    {
      id: 3,
      title: "Schemes",
    },
    {
      id: 4,
      title: "Events",
    },
  ];

  const chnageCurrentTab = (e, idx) => {
    setCurrentTab(idx);
  };

  useEffect(() => {
    fetchApi("notice").then((data) =>setGetNotices(data.data.notice));
    fetchApi("tender").then((data) =>setGetTender(data.data.tender));
    fetchApi("event").then((data) =>setGetEvent(data.data.event))
  }, []);

  return (
    <div className="notice">
      <div className="container">
        <div className="about-banner">
          <img src={aboutHeader} alt="" />
        </div>
        <div className="about-current-nav">
          <div className="about-link">
            <Link className="link" to="/">
              Home ❯
            </Link>
            <Link className="link" to="/about">
              About Us ❯
            </Link>
            <Link className="link">{tabData[currentTab].title}</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {tabData.map((item, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={index}
                      onClick={(e) => chnageCurrentTab(e, index)}
                    >
                      <i class="bx bx-chevrons-right"></i>
                      {item.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey={0}>
                  <Notices title={tabData[currentTab].title} getNotices={getNotices} />
                </Tab.Pane>
                <Tab.Pane eventKey={1}>
                  <Tenders title={tabData[currentTab].title} getTender={getTender} />
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <Schemes title={tabData[currentTab].title} />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <Events title={tabData[currentTab].title} getEvent={getEvent}/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};

export default Notice;
