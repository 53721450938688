import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "./sidemenu.css"
const SideMenu = ({ toggleDrawer, isOpen }) => {

    const [dropdownShow1, setDropdownShow1] = useState(false);
    const [dropdownShow2, setDropdownShow2] = useState(false);
    const [dropdownShow3, setDropdownShow3] = useState(false);
    const [dropdownShow4, setDropdownShow4] = useState(false);

    // const [isOpen,setIsOpen] = useState(true);

    return (
        <div className={`side-menu ${isOpen ? "side-menu-show" : ""}`}>
            <div className="close" onClick={toggleDrawer}><span><i class='bx bx-x'></i></span></div>
            <div className="container p-0">
                <div className="navBar-item">
                    <Link to="/" className="main link" onClick={toggleDrawer}><i className='bx bxs-home'></i></Link>
                    <div className="dropdown">
                        <div className="d-title" onClick={() => setDropdownShow1(!dropdownShow1)}>Organizations  <span className="d-icon"><i className={`bx bx-chevron-${dropdownShow1 ? "up" : "down"}`}></i></span>  </div>
                        <div className={`dropdown-show ${dropdownShow1 ? 'show' : ''}`}>
                            <Link className="link" to="/org/0" onClick={toggleDrawer}>Sports Authority of India</Link>
                            <Link to="/org/1" className="link" onClick={toggleDrawer}>CEM Message</Link>
                        </div>
                    </div>
                    <div className="dropdown">
                        <div className="d-title" onClick={() => setDropdownShow2(!dropdownShow2)}>Information & Service  <span className="d-icon"><i className={`bx bx-chevron-${dropdownShow2 ? "up" : "down"}`}></i></span>  </div>
                        <div className={`dropdown-show ${dropdownShow2 ? 'show' : ''}`}>
                            <Link to="/notice/0" className="link" onClick={toggleDrawer}>Notice</Link>
                            <Link to="/notice/1" className="link" onClick={toggleDrawer}>Tenders</Link>
                            <Link to="/notice/2" className="link" onClick={toggleDrawer}>Schemes</Link>
                            <Link to="/notice/3" className="link" onClick={toggleDrawer}>Events</Link>
                        </div>
                    </div>
                    <div className="dropdown">
                        <div className="d-title" onClick={() => setDropdownShow3(!dropdownShow3)}>Documents  <span className="d-icon"><i className={`bx bx-chevron-${dropdownShow3 ? "up" : "down"}`}></i></span>  </div>
                        <div className={`dropdown-show ${dropdownShow3 ? 'show' : ''}`}>
                            <Link className="link" to="/documents/0" onClick={toggleDrawer}>Roadmap</Link>
                            <Link className="link" to="/documents/1" onClick={toggleDrawer}>Vision for 2025</Link>
                            <Link className="link" to="/documents/2" onClick={toggleDrawer}>Action Plan</Link>
                            <Link className="link" to="/documents/3" onClick={toggleDrawer}>Calendar</Link>
                        </div>
                    </div>
                    {/* <div className="dropdown">
                        <div className="d-title" onClick={() => setDropdownShow4(!dropdownShow4)}>About Us  <span className="d-icon"><i className={`bx bx-chevron-${dropdownShow4 ? "up" : "down"}`}></i></span>  </div>
                        <div className={`dropdown-show ${dropdownShow4 ? 'show' : ''}`}>
                            <Link className="link" to="/about" onClick={toggleDrawer}>Who we are</Link>
                            <Link className="link" to="/about" onClick={toggleDrawer}> Mission & Vision</Link>
                        </div>
                    </div> */}
                    <Link to="/about" className="link r-link" onClick={toggleDrawer}>About Us</Link>
                    <Link to="/gallery" className="link r-link" onClick={toggleDrawer}>Gallery</Link>
                    <Link to="/contact" className="link  r-link" onClick={toggleDrawer}>Contact Us</Link>
                </div>
            </div>
        </div>
    )
}

export default SideMenu