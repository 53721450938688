import React, { useEffect, useState } from 'react'
import { createSearchParams, Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer'
import Navbar from '../../components/navbar/Navbar'
import Carousel from 'react-bootstrap/Carousel';
import gameTourImg from '../../assets/images/games_tournament_t.png'
import gameEventImg from '../../assets/images/Financial Assistance_B.png'
import clickIcon from '../../assets/icon/click.jpg';
import sliderImg1 from '../../assets/images/slider1.jpg';
import sliderImg2 from '../../assets/images/slider2.jpg';
import sliderImg3 from '../../assets/images/slider-3.jpeg';
import sliderImg4 from '../../assets/images/slider-4.jpeg';
// import btrLogo from '../../assets/logo/btrgov.png';
import brandImg2 from '../../assets/logo/2.jpg';
import brandImg3 from '../../assets/logo/3.jpg';
import brandImg4 from '../../assets/logo/4.jpg';
import brandImg5 from '../../assets/logo/5.png';
import brandImg6 from '../../assets/logo/6.jpg';
import brandImg7 from '../../assets/logo/7.jpg';
import brandImg8 from '../../assets/logo/8.jpg';
import sportsAcademy from '../../assets/images/sportsAcademy.png';
import kheloIndia from '../../assets/images/kheloIndia.jpg';
import statelevel from '../../assets/images/statelevel.jpg';
import fb from "../../assets/images/facebook.png"
import tw from "../../assets/images/twitter.png"
import ig from "../../assets/images/instagram.png"
import yt from "../../assets/images/youtube.png"
import Slider from "react-slick";
import './home.css';
import axios from 'axios';
import { fetchApi } from '../../apis/FatchApis';
import { useNavigate } from 'react-router-dom';
import newImg from "../../assets/icon/new.gif"

const sliderData = [
  {
    img: sliderImg1,
  },
  {
    img: sliderImg2,
  },
  {
    img: sliderImg3,
  },
  {
    img: sliderImg4,
  }
]

const brandsSlider = [
  {
    img: brandImg2,
  },
  {
    img: brandImg2,
  },
  {
    img: brandImg3,
  },
  {
    img: brandImg4,
  },
  {
    img: brandImg5,
  },
  {
    img: brandImg6,
  },
  {
    img: brandImg7,
  },
  {
    img: brandImg8,
  },
]
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3
      }
    },
    // {
    //   breakpoint: 480,
    //   settings: {
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    //   }
    // },
    {
      breakpoint: 536,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }
    }
  ]
};


const Home = () => {
  const [getNotices, setGetNotices] = useState([]);
  const [getImages, setGetImages] = useState([]);
  const navigate  = useNavigate();

  const viewNotice = (item)=>{
    navigate( "/show-page",{
      // pathname:
      // search:createSearchParams({
      //   item: item
      // }).toString()
      state:{
        item: item,
      }

    })

  }



  useEffect(() => {
    fetchApi("notice").then((data) =>setGetNotices(data.data.notice));
  }, []);


  useEffect(() => {
    fetchApi("gallery/allImages").then((data) =>{ 
      setGetImages(data.data.gallery)
     }
    );
  }, []);







  return (
    <div className="hero">
      <div className="container">

        <div className="row">
          <div className="col-lg-6 col-md-12 ">
            <div className="slider">
              <Carousel indicators={false}>
                {
                  getImages.map((item, index) => (

                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={item.gallery_image}
                        alt=""
                      />
                    </Carousel.Item>

                  ))

                }

              </Carousel>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="left-col-container">
                  <div className="game-torna">
                    <img src={gameTourImg} alt="" />
                    <Link className="link ">games tournament</Link>
                  </div>
                  <div className="coaching">
                    <img src={gameTourImg} alt="" />
                    <Link className="link ">coaching</Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="hero-link-teg">

                  <Link className="link">
                    <span className="hero-ul-icon">
                      <i className='bx bx-chevrons-right'></i>
                    </span>
                    sustainable development goals(sdgs)
                  </Link>
                  <img src={clickIcon} alt="" />
                </div>
                <ul className="hero-ul">
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-chevrons-right'></i>
                      </span>
                      RTI</Link>
                  </li>
                  <li>
                    <Link className="link" to='/notice'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-chevrons-right'></i>
                      </span>
                      Tenders</Link>
                  </li>
                  <li>
                    <Link className="link" to='/gallery'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-chevrons-right'></i>
                      </span>
                      Media Gallery</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-chevrons-right'></i>
                      </span>
                      Fit India Freedom Run 2.0</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-chevrons-right'></i>
                      </span>
                      Fit India Fitness Protocol</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row how-do-i mt-3">
          <div className="col-lg-6 col-md-12">
            <h3>Recent Notification</h3>

             {/* <Link className="link">
                      <span className="hero-ul-icon">
                        <i className='bx bx-right-arrow-alt'></i>
                      </span>
                      Latest Notice:
                      </Link>
                      <button className="btn btn-outline-secondary" onClick={()=>viewNotice(item)}>View</button> */}
                      {/* <div  onClick={()=>viewNotice(item)} style={{cursor:'pointer'}}>
                      <img src={newImg} alt=""  width="43" />
                      </div>
                      <div className='marquee' onClick={()=>viewNotice(item)} style={{cursor:'pointer'}}>
                        <p> {item.notice_title}</p>
                      </div> */}

            <hr />
            <marquee behavior="scroll" direction="up" scrollamount="1"  width="100%" >
            <ul>
                {
                
                  getNotices.slice(0,3).map((item,index)=>(
                       <li onClick={()=>viewNotice(item)} style={{cursor:'pointer' , listStyleType:'none'}} key={index} className='p-2'><img src={newImg} alt=""  width="43" /> {item.notice_title} </li>      
                  ))
                
                }
                </ul>
            </marquee>
           
          </div>
          <div className="col-lg-3 col-md-12">
            <h3>How do I?</h3>
            <hr />
            <ul className="hero-ul">
              <li>
                <Link className="link" to='/'>
                  <span className="hero-ul-icon">
                    <i className='bx bx-right-arrow-alt'></i>
                  </span>
                  Apply for Financial Assistance</Link>
              </li>
              <li>
                <Link className="link" to='/'>
                  <span className="hero-ul-icon">
                    <i className='bx bx-right-arrow-alt'></i>
                  </span>
                  Apply for Sports Equipments</Link>
              </li>
              <li>
                <Link className="link" to='/'>
                  <span className="hero-ul-icon">
                    <i className='bx bx-right-arrow-alt'></i>
                  </span>
                  Apply for Sports Certificate</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-12">
            <h3>Latest</h3>
            <hr />
            <ul className="hero-ul">
              <li>
                {/* <Link className="link" to='/'>
                  <span className="hero-ul-icon">
                    <i className='bx bx-right-arrow-alt'></i>
                  </span>
                  Title goes here</Link> */}
                  <a href="https://ticketify.in/Dashboard/public/" className='btn btn-primary'>BOOK TICKET FOR DURANDCUP 2023</a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" game-event d-flex justify-content-center align-items-center flex-wrap">
          <div className="event">
            <img src={gameEventImg} alt="" />
            <Link className="link mt-2"><h6> Financial Assistance </h6></Link>
          </div>
          <div className="event">
            <img src={sportsAcademy} alt="" />
            <Link className="link mt-2"><h6> State Sports Academy </h6></Link>
          </div>
          <div className="event">
            <img src={kheloIndia} alt="" />
            <Link className="link mt-2"><h6> Khelo India Under MYAS, Govt. of India</h6></Link>

          </div>

          <div className="event">
            <img src={statelevel} alt="" />
            <Link className="link mt-2"><h6> State Level Committee for Students & Youth Welfare</h6></Link>
          </div>
        </div>
      </div>

      <div className="container-fluid info-services-container p-0 mt-3">
        <div className="info-services-head"></div>

        <div className="info-services">

          <div className="container">
            <div className="row ">
              <div className="col-lg-4 col-md-12">
                <div className="service-header">
                  <h3>Information & Services</h3>
                </div>
                <ul className="hero-ul">
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Infrastructure</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Sports Calendar</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Grants-in-Aid</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Khelo India</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      NSS - National Service Scheme</Link>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-12">
                <div className="service-header">
                  <h3>Policies</h3>
                </div>
                <ul className="hero-ul">
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Copyright Policy</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Hyperlinking Policy</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Privacy Policy</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Accessibility Statement</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Terms of Use</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Content Management</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="service-header">
                  <h3>About the Government</h3>
                </div>
                <ul className="hero-ul">
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      Bodoland Govt. Portal</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      CEM Dashboard</Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <span className="hero-ul-icon">
                        <i className='bx bx-square'></i>
                      </span>
                      ISPL</Link>
                  </li>
                </ul>
                <div className="service-header">
                  <h3>FOLLOW US ON</h3>
                </div>
                <ul className="hero-ul social-link  d-flex">
                  <li>
                    <Link className="link" to='/'>
                      <img src={fb} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <img src={yt} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <img src={tw} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to='/'>
                      <img src={ig} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="brand mb-3">
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col-12 home-bottom-slider">
                <Slider {...settings}>
                  {
                    brandsSlider.map((item, index) => (
                      <div className="m-0" key={index}>
                        <img
                          className="m-0"
                          src={item.img}
                          alt=""
                        />
                      </div>
                    ))
                  }
                </Slider>
              </div>

            </div>
          </div>
        </div>


      </div>

    </div>

  )
}

export default Home