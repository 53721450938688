import React from 'react'

const WhatWeDo = ({ title }) => {
  return (
    <div className="WhatWeDo">
      <div className="container">
        <h3>MISSION </h3>
        <hr />
          <p style={{textAlign:"justify"}}>
            To fulfill the designed vision of the Department in a pragmatic manner, a definite and constructive mission is the pivotal way. 
            Sports and infrastructure are the most closely associated aspects that play a crucial role in enhancing any sports persons 
            performance to a higher level of achievement. The department emphasizes on ensuring a pragmatic approach towards structured 
            mission, promotion of competitive sports, physical activity, a healthy lifestyle, state of art sports infrastructure, 
            sports coaching and training and sports related activities. The Department is also committed with a mission to reach every 
            nook and corner of the BTR to identify budding sports talent in different sports disciplines. Further, our mission is to promote
            and develop sports performances at State, National and International level competitions along with health awareness and improving
            quality of life which is the core mission of Sports & Youth Welfare Department, BTC, Kokrajhar.</p>

          <h3>VISION</h3>
          <hr />
          <p style={{textAlign:"justify"}}>
            The vision of Sports & Youth Welfare Department is to bring about social transformation by promoting sports and spreading
            awareness of sports across different sections of the society, resulting in character building and inclusive development. It 
            focuses on the all-round development of body, mind and health of the youth and common citizen of the BTR. An individual 
            self-esteem, confidence and ability to deal with stress effectively is one of the major building blocks of a community 
            and nation and therefore, the Department of Sports & Youth Welfare emphasizes on identifying and nurturing sporting talent
            in Bodoland giving them the opportunity to shine and excel in the global sports arena by creating a sports centric ecosystem.
          </p>

      </div>
    </div>
  )
}

export default WhatWeDo