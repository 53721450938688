import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import './navbar.css';
import SideMenu from './SideMenu';


const NavbarB = () => {
    const NavBarRootRef = useRef();
    useEffect(() => {
        window.addEventListener('scroll', (event) => {

            if (window.scrollY >= 10) {
                NavBarRootRef && NavBarRootRef.current && NavBarRootRef.current.classList.add("navbar-sticky");

            } else {
                NavBarRootRef && NavBarRootRef.current && NavBarRootRef.current.classList.remove("navbar-sticky");
            }
        });
    },[])

    const [isOpen, setIsOpen] = useState(false)
    const width = window.innerWidth;
    const breakpoint = 960;

    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };




    return (
        <div className="mt-4" >
            <div className="navbarCustom" ref={NavBarRootRef}>
                <div className="container p-0">
                    <div className="navBar-item">
                        {
                            width > breakpoint ? <>
                                <Link to="/" className="main link"><i className='bx bxs-home'></i></Link>
                                <div className="dropdown">
                                    <div className="d-title">Organizations  <span className="d-icon"><i class='bx bx-chevron-down'></i></span> </div>
                                    <div className="dropdown-show">
                                        <Link className="link" to="/org/0">Sports Authority of India</Link>
                                        <Link to="/org/1" className="link">CEM Message</Link>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <div className="d-title">Information & Service  <span className="d-icon"><i class='bx bx-chevron-down'></i></span> </div>
                                    <div className="dropdown-show">
                                        <Link to="/notice/0" className="link">Notice</Link>
                                        <Link to="/notice/1" className="link">Tenders</Link>
                                        <Link to="/notice/2" className="link">Schemes</Link>
                                        <Link to="/notice/3" className="link">Events</Link>
                                    </div>
                                </div>
                                <div className="dropdown">
                                    <div className="d-title">Documents  <span className="d-icon"><i class='bx bx-chevron-down'></i></span> </div>
                                    <div className="dropdown-show">
                                        <Link className="link" to="/documents/0">Roadmap</Link>
                                        <Link className="link" to="/documents/1">Vision for 2025</Link>
                                        <Link className="link" to="/documents/2">Action Plan</Link>
                                        <Link className="link" to="/documents/3">Calendar</Link>
                                    </div>
                                </div>
                                <Link to="/about" className="link r-link">About us</Link>
                                {/* <div className="dropdown">
                                    <div className="d-title">About Us  <span className="d-icon"><i class='bx bx-chevron-down'></i></span> </div>
                                    <div className="dropdown-show">
                                        <Link className="link" to="/about">Who we are</Link>
                                        <Link className="link" to="/about/1"> Mission & Vision</Link>
                                    </div>
                                </div> */}
                                <Link to="/gallery" className="link r-link">Gallery</Link>
                                <Link to="/contact" className="link  r-link">Contact Us</Link>

                            </> : <>
                                <div className="ham-menu" onClick={() => setIsOpen(!isOpen)}>
                                    {/* <span><i className={`bx bx-${isOpen ? "x" :"menu"} `}></i></span> */}
                                    <span><i className={`bx bx-menu`}></i></span>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            {width < breakpoint ? <SideMenu toggleDrawer={toggleDrawer} isOpen={isOpen} /> : ""}
        </div>

    )
}

export default NavbarB;