import React from 'react'

const WhoWeAre = ({title}) => {
    return (
        <div className="whoWeAre">
            <div className="container">
                <h3>WHAT WE DO</h3>
                <hr />
                <h4>Refresher Course:</h4>
                <p style={{textAlign:"justify"}}>Imparting Training and Refresher courses to the all Officers, Staff, Coaches and Physical Instructor under Sports & youth Welfare department BTC, Kokrajhar</p>
                
                <h4>Financial Assistance to National & international arena participation:</h4>
                <p style={{textAlign:"justify"}}>The aims of scheme is to provided financial assistance to the National and International players for traveling expenses as Lum-Sum during the participation in various tournament and various discipline in National and International area. Applicant should apply with due fill up format provide by the department and countersigned by the concerned DSO under BTR.</p>
              
                <h4>Cash Award:</h4>
                <p style={{textAlign:"justify"}}>The main aims of the scheme is providing One time financial assistant grant "Incentive Cash Award" to medal achievers who have participated in the various tournament in National & International arena as Encouragement Award. The department is also providing one time financial assistance to above 60 years old Sportsperson as Ex-Gratia. Applicant should apply with due fill up format provide by the department and countersigned by the concerned DSO under BTR for accepted time periods bounded.</p>
            </div>
        </div>
    )
}

export default WhoWeAre