import React from 'react'
import './contact.css'
import aboutHeader from '../../assets/images/about.jpg';
import { Link } from 'react-router-dom';
import memberImg from "../../assets/images/member.png";
const Contact = () => {
   return (
      <div className='contact-us'>
         <div className="container">
            <div className="about-banner">
               <img src={aboutHeader} alt="" />
            </div>
            <div className="contact-current-nav">
               <div className="contact-link">
                  <Link className="link">Home ❯ </Link>
                  <Link className="link">Contact us ❯</Link>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <h1 className="h1">Contact Us</h1>
                  <div className="contact-address">
                     <p><strong>Directorate of Sports & Youth Welfare</strong><br />
                        Government of Bodoland Territorial Region,
                        <p>Titaguri, Kokrajhar, BTR (Assam) - 783370</p>
                        Email: <Link to="/">: chdsportsbtc2019@gmail.com</Link></p>

                  </div>
                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <h2 className="h2">Whom to Contact for:</h2>
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                    <p>Sri Swmdwn Muchahary</p>
                                    <p>I/c, CHD, Sports & Youth Welfare</p>
                                    <p>BTC, Kokrajhar.</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                    <p>Sri Motilal Borgoyary</p>
                                    <p>Asstt. Liaison Officer</p>
                                    <p>O/o the Directorate of Sports & Youth Welfare</p>
                                    <p>Kokrajhar, BTC</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                 <p>Sri Swmdwn Muchahary 
</p>
                                    <p>District Sports Officer,</p>
                                    <p>Udalguri, BTC.</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                 <p>Sri Dasarath Brahma
</p>
                                    <p>District Sports Officer, I</p>
                                    <p>Chirang, BTC.
</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                 <p>Sri Debanga Mahilary

</p>
                                    <p>District Sports Officer, I/c
</p>
                                    <p>Baksa, BTC.

</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                 <p>Sri Bhupen Rabha

</p>
                                    <p>Sub-divisional Sports Officer
</p>
<p>
O/o the District Sports Office,
</p>
                                    <p>Udalguri, BTR.


</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="member-contact">
                     <div className="row ">
                        <div className="col-md-8 col-sm-12 member-details">
                           <div className="row ">
                              <div className="col-12 d-flex justify-content-between mt-3 p-2">
                                 <div className="member-add mt-4">
                                 <p>Sri Anil Kr. Daimary

</p>
                                    <p>Inspector of Physical Education,

</p>
<p>
O/o the District Sports Office

</p>
                                    <p>Udalguri, BTC. 


</p>
                                 </div>
                                 <div className="member-img">
                                    <img src={memberImg} alt="" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   )
}

export default Contact