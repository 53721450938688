import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
const Events = (props,{ title }) => {
  const eventData = props.getEvent;

  return (
    <div className="container">
      <h1 className="h1">{title}</h1>
      <div className="content">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <td><Link className="link" to="/show-page">MOUNTAINEERING TRAINING FOR THE YEAR 2022-23</Link></td>
              <td><Link className="link"  to="/show-page">2022-23</Link></td>
              <td><a className="link"  href='../../assets/download/4a.pdf' download>Download</a></td>
            </tr>
          <tr>
              <td><Link className="link" to="/show-page">WOMEN SPORTS MEET FOR THE YEAR 2022-23</Link></td>
              <td><Link className="link"  to="/show-page">2022-23</Link></td>
              <td><a className="link"  href='../../assets/download/4b.pdf' download>Download</a></td>
            </tr>
          <tr>
              <td><Link className="link" to="/show-page">BODOLAND INDIGENOUS GAMES FOR THE YEAR 2022-23</Link></td>
              <td><Link className="link"  to="/show-page">2022-23</Link></td>
              <td><a className="link"  href='../../assets/download/4c.pdf' download>Download</a></td>
            </tr> */}

            {eventData &&
              eventData.map((notice, index) => (
                <tr key={index} id={notice.id}>
                  {
                    <>
                      <td>{notice.notice_title}</td>
                      <td>{notice.notice_description}</td>
                      <td>{notice.entrusted_dept}</td>
                      <td>{notice.notice_publish_date}</td>
                      <td>
                        <a className="link" href={notice.notice_file} download>
                          Download
                        </a>
                      </td>
                    </>
                  }
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Events;
