import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

const Divisions = ({title}) => {
  return (
    <div className="divisions">
       <div className="container">
       <h3>OUR DIVISIONS/FIELD OFFICES</h3>
       <hr />
       {/* <Link className="link" to="">download</Link> */}
       <p>NAME & CONTACT NUMBER OF DISTRICT SPORTS OFFICERS AND SUB-ORDINATE OFFICERS UNDER SPORTS & YOUTH WELFARE DEPTT. BTC, KOKRAJHAR.
</p>
       </div>
        <div className="container">
        <Table bordered hover>
      <thead>
        <tr>
          <th>Sl No.</th>
          <th>District</th>
          <th>Name of Officer</th>
          <th>Designationr</th>
          <th>Contact Number</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Udalguri</td>
          <td>Sri Swmdwn Muchahary</td>
          <td>District Sports Officer</td>
          <td>8132801530</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>2</td>
          <td>-Do-</td>
          <td>Sri Bhupen Rabha</td>
          <td>SDSO</td>
          <td>9678421536</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>3</td>
          <td>-Do-</td>
          <td>Sri Anil Daimary</td>
          <td>IPE</td>
          <td>7002883737</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>4</td>
          <td>Kokrajhar</td>
          <td>Sri Pradip Kr. Narzary</td>
          <td>District Sports Officer, I/c</td>
          <td>9101017374</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>5</td>
          <td>Chirang</td>
          <td>Sri Dasarath Brahma</td>
          <td>District Sports Officer, I/c</td>
          <td>8133846092</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>6</td>
          <td>Baksa</td>
          <td>Sri Debanga Mahilary</td>
          <td>District Sports Officer, I/c</td>
          <td>6000519780</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
        <tr>
          <td>7</td>
          <td>Kokrajhar</td>
          <td>Sri Motilal Borgoyary</td>
          <td>SDSO attached to CHD Office</td>
          <td>9435720466</td>
          {/* <td>  <Link className="link" to="">download</Link></td> */}
        </tr>
      
    
      </tbody>
    </Table>
        </div>
    </div>
  )
}

export default Divisions