import React, { useEffect, useState } from "react";
import "./gallery.css";
import { fetchApi } from "../../apis/FatchApis";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const Gallery = () => {
  const [getImages, setGetImages] = useState([]);
  const [isLaoding ,setIsLaoding] = useState(false)

  useEffect(() => {
    fetchApi("gallery/allImages").then((data) =>
     { setGetImages(data.data.gallery)
      setIsLaoding(true)}
    );
  }, []);

  return (
    <div className="gallery">
      <div className="container">
        <h3 className="h1 mt-3 py-2" style={{ fontSize: "18px" }}>
          Our Image Gallery
        </h3>
        {/* <p className="p-2 my-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit eveniet, labore ducimus doloribus doloremque deleniti hic debitis nam illo dolores error, nulla sapiente est, dignissimos odit ex? Tempora, repudiandae minus.</p> */}
      </div>
      <div className="gallery-container">
        <div className="d-flex justify-content-center algin-items-center  gallery-img-box flex-wrap">
          {/* {getImages && (
            <PhotoProvider>
              {getImages.map((item, index) => (
                <PhotoView src={item.gallery_image} key={index}>
                  <img src={item.gallery_image} className="gallery-img" />
                </PhotoView>
              ))}
            </PhotoProvider>
          )} */}
          {
            isLaoding?<PhotoProvider>
            {getImages.map((item, index) => (
              <PhotoView src={item.gallery_image} key={index}>
                <img src={item.gallery_image} className="gallery-img" />
              </PhotoView>
            ))}
          </PhotoProvider>:<h1>Loading images.........</h1> 

          }
        </div>
      </div>
    </div>
  );
};

export default Gallery;
