import React from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Tenders = (props,{ title }) => {
  const tenderData = props.getTender;

  return (
    <div className="container">
      <h3>Department Tender</h3>
      <div className="content">
        <Table striped bordered hover>
          <thead>
            <tr>
               <th>Id</th>
              <th>Title</th>
              <th>Description</th>
              <th style={{width:"15%"}}>Published Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
          { tenderData && tenderData.map((notice, index) => (
              <tr key={index} id={notice.id}>
                {
                  <>
                  <td>{index+1}</td>
                  <td>
                    {notice.tender_name}
                  </td>
                  <td>
                    {notice.tender_description}
                  </td>
                  <td>
                    {notice.published_date}
                  </td> 
                    <td><a className="link"  href={notice.document} download>Download</a></td>
                  </>
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Tenders