import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";

const Notices = (props, { title }) => {
  const notciesData = props.getNotices;
  return (
    <div className="container">
      <h3>Department Notice</h3>
      <hr />
      <div className="content">
        <Table bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Title</th>
              <th>Description</th>
              <th style={{width:"15%"}}>Published Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
            { notciesData && notciesData.map((notice, index) => (
              <tr key={index} id={notice.id}>
                {
                  <>
                  <td>
                    {index+1}
                  </td>
                  <td style={{textAlign:'justify'}}>
                    {notice.notice_title} 
                  </td>
                  <td style={{textAlign:'justify'}}>
                    {notice.notice_description}
                  </td>
                  
                  <td>
                    {notice.notice_publish_date}
                  </td> 
                    <td><a className="link btn btn-outline-secondary"  href={notice.notice_file} download>Download</a></td>
                  </>
                }
              </tr>
            ))}
            {
              // console.log(notciesData.notice[0])
            }
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Notices;
