import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';

const Guideline = ({title}) => {
  return (
    <div className="divisions">
       <div className="container">
       <h1 className="h1">{title}</h1>
       {/* <Link className="link" to="">download</Link> */}
       </div>
       <div className="container">
        <Table bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Size</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Action Plan</td>
              <td>369KB</td>
              <td><a className="link" href='../../assets/download/actionplan.pdf' download >Download</a></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Guideline;