import React from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Schemes = ({ title }) => {
  return (
    <div className="container">
      <h1 className="h1">{title}</h1>
      <div className="content">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Title</th>
              <th>Date</th>
              <th>Document</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Playground Development Schemes under SOPD Fund, 2021-22</td>
              <td>2021-22</td>
              <td><a className="link"  href='../../assets/download/schemes.pdf' download>Download</a></td>
            </tr>
          
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Schemes