import React, { useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

const ShowPage = (props) => {

  const location =  useLocation()
  const [item, setItem] = useState(location.state.item);


  return (
    <div className='show-page'>
       {
        item && (
          <div className="container notice mt-5">
            <h3>{item.notice_title}</h3>
            <hr />
            <h5>Publish Date: {item.notice_publish_date}</h5>
             {/* <h3>Entrusted Dept: {item.entrusted_dept}</h3> */}
            <p className='mt-4'>{item.notice_description}</p>
            <a className="link btn btn-outline-secondary"  href={item.notice_file} target='_blank'>Download</a>
           
       </div>
        )
       }
    </div>
  )
}

export default ShowPage