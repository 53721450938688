import React,{useEffect, useState} from 'react'
import aboutHeader from '../../assets/images/about.jpg';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useParams } from 'react-router-dom'
import './org.css';
import Sports from './Sports';
import Board from './Board';

const Organizations = () => {
    const params = useParams();
    const defaultTab = parseInt(params.idx);
    const [currentTab, setCurrentTab] = useState(defaultTab);

    const tabData = [
        {
            id: 1,
            title: 'Sports Authority of India',
        },
        {
            id: 2,
            title: 'CEM Message'
        },
    ]

    // useEffect(() => {
    //        localStorage.setItem('currentTabOrg',currentTab || defaultTab );
    //   }, [currentTab]);


    return (
        <div className="notice">
            <div className="container">
                <div className="about-banner">
                    <img src={aboutHeader} alt="" />
                </div>
                <div className="about-current-nav">
                    <div className="about-link">
                        <Link className="link" to="/">Home ❯ </Link>
                        <Link className="link" to="/org">Organizations ❯</Link>
                        <Link className="link">{tabData[currentTab].title}</Link>
                    </div>
                </div>
            </div>
            <div className="container">
                <Tab.Container id="left-tabs-example" defaultActiveKey={currentTab}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                {
                                    tabData.map((item, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={index} onClick={() => setCurrentTab(index)}>
                                                <i class='bx bx-chevrons-right'></i>{item.title}</Nav.Link>
                                        </Nav.Item>
                                    ))
                                }
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey={0}>
                                    <Sports title={tabData[currentTab].title} />
                                </Tab.Pane>
                                <Tab.Pane eventKey={1}>
                                    <Board title={tabData[currentTab].title} />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
  )
}

export default Organizations