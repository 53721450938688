import React, { useState } from 'react';
import aboutHeader from '../../assets/images/about.jpg';
import './about.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import History from './History';
import { Link, useParams } from 'react-router-dom';
import WhoWeAre from './WhoWeAre';
import WhatWeDo from './WhatWeDo';
import Divisions from './Divisions';

const About = () => {

  const [currentTab, setCurrentTab] = useState(0);
  const params = useParams();
  const defaultTab = parseInt(params.idx);
  const tabData = [
    {
      id: 1,
      title: 'Mission & Vision'
    },
    {
      id: 2,
      title: 'What We Do'
    },
    {
      id: 3,
      title: 'History'
    },
    {
      id: 4,
      title: 'Our Divisions/Field Offices'
    },
  ]


  return (
    <div className="about-us">
      <div className="container">
        <div className="about-banner">
          <img src={aboutHeader} alt="" />
        </div>
        <div className="about-current-nav">
          <div className="about-link">
            <Link className="link" to="/">Home ❯ </Link>
            <Link className="link" to="/about">About Us ❯</Link>
            <Link className="link">{tabData[currentTab].title}</Link>
          </div>
        </div>
      </div>
      <div className="container">
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                {
                  tabData.map((item, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={index} onClick={() => setCurrentTab(index)}><i class='bx bx-chevrons-right'></i>{item.title}</Nav.Link>
                    </Nav.Item>
                  ))

                }
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey={0}>
                  <WhatWeDo title={tabData[currentTab].title} />
                </Tab.Pane>
                <Tab.Pane eventKey={1}>
                  <WhoWeAre title={tabData[currentTab].title} />
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <History title={tabData[currentTab].title} />
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <Divisions title={tabData[currentTab].title} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  )
}

export default About