import React from 'react'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const Sports = ({ title }) => {
  return (
    <div className="container">
      <h3>{title}</h3>
      <hr />
      <div className="content">
          <a className='btn btn-outline-secondary' href="https://sportsauthorityofindia.nic.in/sai/" target='_blank'>Official Website of SAI</a>
      </div>
    </div>
  )
}

export default Sports